import {useState} from 'react'
import './NewRoutine.scss'

export const NewRoutine = ({adding, add, close}) => {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [name, setName] = useState('')
  const [showError, setShowError] = useState(0)
  let ErrorMessage = () => ''
  let addRoutine = (e) => {
    e.preventDefault()
    if (!from) {
      setShowError(2)
      return
    }
    const from1 =
      parseInt(from.split(':')[0]) * 100 + parseInt(from.split(':')[1])
    let to1
    if (to) {
      to1 = parseInt(to.split(':')[0]) * 100 + parseInt(to.split(':')[1])
      if (from1 >= to1) {
        setShowError(1)
        return
      }
    }
    if (!name) {
      setShowError(3)
      return
    }
    add({
      from: from1,
      ...(to1 && {to: to1}),
      name,
    })
    setFrom('')
    setTo('')
    setName('')
  }

  let addToRoutine = ''
  if (adding) {
    if (showError) {
      setTimeout(() => setShowError(0), 4500)
      ErrorMessage = () => {
        switch (showError) {
          case 1:
            return (
              <div data-testid="error-message-1"
                   className="is-flex has-background-danger-light is-justify-content-center has-text-danger-dark is-bold p-3">
                <strong className="has-text-danger-dark">to</strong>
                &nbsp;should be after&nbsp;
                <strong className="has-text-danger-dark">from</strong>
              </div>
            )
          case 2:
            return (
              <div data-testid="error-message-2"
                   className="is-flex has-background-danger-light is-justify-content-center has-text-danger-dark is-bold p-3">
                <strong className="has-text-danger-dark">from</strong>
                &nbsp;is required for routine
              </div>
            )
          case 3:
            return (
              <div data-testid="error-message-3"
                   className="is-flex has-background-danger-light is-justify-content-center has-text-danger-dark is-bold p-3">
                <strong className="has-text-danger-dark">name</strong>
                &nbsp;is required for routine
              </div>
            )
          default:
            return <></>
        }
      }
    }
    addToRoutine = (
      <div>
        <div className="new-routine">
          <form className="field-body" onSubmit={addRoutine}>
            <div className="field from-field has-addons is-justify-content-center is-horizontal ml-2">
              <p className="control">
                <span className="button is-static">from</span>
              </p>
              <p className="control">
                <input
                  data-testid="test-input-from"
                  type="time"
                  className="input"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  name="from"
                  required
                />
              </p>
            </div>
            <div className="field to-field has-addons is-justify-content-center is-horizontal">
              <p className="control">
                <span className="button is-static">to&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </p>
              <p className="control">
                <input
                  data-testid="test-input-to"
                  type="time"
                  className="input"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  name="to"
                />
              </p>
            </div>
            <input
              data-testid="test-input-name"
              type="text"
              className="name-input input mr-3 is-align-self-center"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              placeholder="Routine name"
            />
            <button
              data-testid="test-input-save"
              type="button"
              className="save-button button is-success mr-3 is-align-self-center"
              onClick={addRoutine}
            >
              save
            </button>
            <button
              data-testid="test-input-close"
              type="button"
              className="close-button button is-dark mr-3 is-align-self-center"
              onClick={close}
            >
              close
            </button>
          </form>
        </div>
        <ErrorMessage/>
      </div>
    )
  }
  return addToRoutine
}
