const Meridiem = ({ m, hasTo }) =>
  !hasTo ? <span className="meridiem">{m}</span> : <span>&nbsp;</span>

export const Time = ({ time, hasTo }) => {
  if (time > 1300) {
    const h = Math.floor((time - 1200) / 100)
    const m = (time - 1200) % 100
    let Min = `:${m}`
    if (m === 0) {
      Min = ':00'
    }
    return (
      <div>
        <span className="hour">{h}</span>
        <span className="minute">{Min}</span>
        <Meridiem hasTo={hasTo} m={'pm'} />
      </div>
    )
  }
  const h = Math.floor(time / 100)
  const m = time % 100
  let Min = `:${m}`
  if (m === 0) {
    Min = ':00'
  }
  return (
    <div>
      <span className="hour">{h}</span>
      <span className="minute">{Min}</span>
      <Meridiem hasTo={hasTo} m={'am'} />
    </div>
  )
}
