export function getScheduleToHighlight(schedule, date = new Date()) {
  const now = date.getHours() * 100 + date.getMinutes()
  const len = schedule.length
  let i = 0
  for (const sc of schedule) {
    if (sc.from === now) {
      return sc.from
    }
    if (sc.to !== undefined) {
      if (sc.from < now && now < sc.to) {
        return sc.from
      }
    }
    const next = schedule[(i + 1) % len]
    if (sc.from < now && now < next.from) {
      return sc.from
    }
    if (i + 1 === len && now < sc.from + 100) {
      return sc.from
    }
    i++
  }
  return
}
