import {useState} from 'react'
import './App.scss'
import {getScheduleToHighlight} from './functions/Utilities'
import {NewRoutine} from './components/NewRoutine'
import {Routine} from "./components/Routine";
import {DummyRoutine} from "./components/DummyRoutine";

function App() {
  let Routines = []
  let HeadLine = () => <span>Today's Schedule</span>
  const [routine, setRoutine] = useState(
    JSON.parse(localStorage.getItem('routine') ?? '[]')
  )
  const [adding, setAdding] = useState(false)
  routine.sort((a, b) => {
    if (a.from < b.from) {
      return -1
    }
    if (a.from > b.from) {
      return 1
    }
    return 0
  })
  const onRemove = (from) => {
    routine.splice(
      routine.findIndex((sc) => sc.from === from),
      1
    )
    setRoutine([...routine])
    localStorage.setItem('routine', JSON.stringify(routine))
  }
  const onComplete = (from) => {
    const i = routine.findIndex((sc) => sc.from === from)
    console.info({i, routine})
    routine[i].completed = !routine[i].completed
    setRoutine([...routine])
    localStorage.setItem('routine', JSON.stringify(routine))
  }
  if (!routine || routine.length === 0) {
    HeadLine = () => <span>No Routine set</span>
  } else {
    const toHighlight = getScheduleToHighlight(routine)
    let afterHighlight = false
    for (const r of routine) {
      r.highlight = toHighlight === r.from
      if (afterHighlight) {
        r.completed = false
      }
      Routines.push(<Routine key={r.from} routine={r} onRemove={onRemove} onComplete={onComplete}/>)
      afterHighlight = true
    }
    for (let i = 0; i < 5; i++) {
      Routines.push(<DummyRoutine key={'dummy-' + i}/>)
    }
    setTimeout(() => {
      if (toHighlight) {
        document.getElementById(toHighlight).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }, 200)
  }

  const addRoutine = (r) => {
    routine.push(r)
    setRoutine([...routine])
    localStorage.setItem('routine', JSON.stringify(routine))
  }

  return (
    <div className="App">
      <header className="App-header">
        <HeadLine/>
        <button
          className="button is-primary"
          type="button"
          onClick={() => setAdding(true)}
        >
          Add
        </button>
      </header>
      <div className="routines">
        <NewRoutine
          adding={adding}
          add={addRoutine}
          close={() => setAdding(false)}
        />
        <div className="routine-wrapper">{Routines}</div>
      </div>
    </div>
  )
}

export default App
